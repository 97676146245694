<template>
  <v-content>
    <v-row
      v-if="activeUser"
      class="activeUser"
    >
      <v-col>
        {{ $t("common.selectedUser") }}: {{ activeUser.email }}
        <br />
        <v-btn
        @click="destroySession"
        class="mint text-h4 font-weight-bold darkgrey--text button-style"
        >
          {{ $t('common.logOut') }}
        </v-btn>
      </v-col>
    </v-row>
    <cookie-banner />
    <router-view />
    <dashboard-core-footer v-if="!mobileDevice" />
    <mobile-footer v-if="mobileDevice" />
  </v-content>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'DashboardCoreView',

    components: {
      DashboardCoreFooter: () => import('./Footer'),
      MobileFooter: () => import('./MobileFooter'),
      CookieBanner: () => import('./CookieBanner'),
    },
    computed: {
      ...mapState(['activeUser']),
      mobileDevice () {
        return this.$vuetify.breakpoint.xsOnly
      },
      smDevice () {
        return this.$vuetify.breakpoint.smOnly
      },
    },
    methods: {
      destroySession () {
        this.$store.commit('setActiveUser', null)
        this.$router.push('/manage/users')
      }
    }
  }
</script>
<style scoped>
.activeUser{
font-weight: bold;
  text-align: center;
  padding: 1rem;
  color: #fff;
  background-color: red;
  border-top: 15px solid #fff;
  text-transform: uppercase;
}
.button-style {
  margin-top: 10px;
}
</style>
